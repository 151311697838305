/*
    Creation Time: 2022 - Feb - 23
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

import Splash from '@/views/Common/Splash';

export default Splash;
