import React, { useEffect } from 'react';
import Head from 'next/head';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import SplashLegacy from '@clubpay/customer-common-module/src/component/SplashLegacy';

export default function SplashPage() {
    const { url, push } = useQlubRouter();
    useEffect(() => {
        const timeout = setTimeout(() => {
            push({
                pathname: '/home',
            });
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);
    const hideIcon = url.cc === 'sa' || url.cc === undefined;
    return (
        <>
            <Head>
                <title>{hideIcon ? '' : 'Qlub...'}</title>
            </Head>
            <SplashLegacy hideIcon={hideIcon} />
        </>
    );
}
